<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" class="pt-0">
        <v-card class="elevation-0">
          <v-card-title class="pa-4">
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                <v-btn-toggle>
                  <v-btn color="primary" @click="dispatchMonitoring()">
                    DISPATCH MONITORING
                  </v-btn>
                  <v-btn
                    v-if="$moduleAccess('active monitoring')"
                    color="primary"
                    @click="activeMonitoring()"
                  >
                    ACTIVE MONITORING
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="2"
                lg="2"
                xl="2"
                offset-md="7"
                offset-lg="7"
                offset-xl="7"
              >
                <div class="float-right">
                  <fab-button
                    @click="openCreateDispatchDialog()"
                    dark
                    top
                    xsmall
                    :icon="'mdi-plus'"
                    :tooltipSpan="'Create Dispatch'"
                  ></fab-button
                  >&nbsp;
                  <fab-button
                    @click="getData()"
                    dark
                    top
                    xsmall
                    :icon="'mdi-refresh'"
                    :tooltipSpan="'Refresh'"
                  ></fab-button>
                </div>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
        <v-divider></v-divider>
        <v-card class="elevation-0">
          <v-container fluid>
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-row>
                  <v-col cols="12" class="py-2"></v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                offset-md="2"
                lg="6"
                offset-lg="2"
                xl="6"
                offset-xl="2"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="9"
                    offset-md="3"
                    lg="9"
                    offset-lg="3"
                    xl="9"
                    offset-xl="3"
                    class="py-2"
                  >
                    <div
                      class="float-right d-none d-sm-flex d-sm-none d-md-flex"
                    >
                      <p
                        class="text-xs-right grey--text caption my-2 mx-2"
                        style="font-size: 14px; margin-top: 14px; display: inline-block;"
                      >
                        Row per Page:
                      </p>
                      <v-select
                        style="font-size: 14px; max-width: 60px; display: inline-block; height: 34px;"
                        :items="page"
                        class="grey--text mx-2"
                        v-model="pageLimit"
                        @input="limitClick"
                        dense
                        height="20px"
                        :disabled="this.items.length < 10 ? true : false"
                      ></v-select>
                      <p
                        class="text-xs-center grey--text caption my-2 mx-1"
                        style="font-size: 14px;display: inline-block;"
                      >
                        {{ itemFrom + " -" + itemTo + " of " }} {{ totalItems }}
                      </p>

                      <v-btn
                        style="display: inline-block; height: 34px;"
                        fab
                        light
                        small
                        text
                        @click="backClick"
                        :disabled="disabledBack"
                      >
                        <v-icon dark>mdi-chevron-left</v-icon>
                      </v-btn>
                      <v-btn
                        style="display: inline-block; height: 34px;"
                        fab
                        light
                        small
                        text
                        @click="nextClick"
                        :disabled="disabledNext"
                      >
                        <v-icon dark>mdi-chevron-right</v-icon>
                      </v-btn>
                    </div>
                    <v-row
                      no-gutters
                      class="d-flex d-sm-none d-none d-sm-flex d-md-none"
                    >
                      <v-col cols="12" sm="12">
                        <p
                          class="text-xs-right grey--text caption my-2 mx-2"
                          style="font-size: 14px; margin-top: 14px; display: inline-block;"
                        >
                          Row per Page:
                        </p>
                        <v-select
                          style="font-size: 14px; max-width: 60px; display: inline-block; height: 34px;"
                          :items="page"
                          class="grey--text mx-2"
                          v-model="pageLimit"
                          @input="limitClick"
                          dense
                          height="20px"
                          :disabled="this.items.length < 10 ? true : false"
                        ></v-select>
                      </v-col>
                      <v-col cols="2" sm="2">
                        <div class="float-left float-sm-left">
                          <v-btn
                            style="display: inline-block; height: 34px;"
                            fab
                            light
                            small
                            text
                            @click="backClick"
                            :disabled="disabledBack"
                          >
                            <v-icon dark>mdi-chevron-left</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="8" sm="8" style="text-align: center;">
                        <p
                          class="text-xs-center grey--text caption my-2 mx-1"
                          style="font-size: 14px; display: inline-block;"
                        >
                          {{ itemFrom + " -" + itemTo + " of " }}
                          {{ totalItems }}
                        </p>
                      </v-col>
                      <v-col cols="2" sm="2">
                        <div class="float-right float-sm-right">
                          <v-btn
                            style="display: inline-block; height: 34px;"
                            fab
                            light
                            small
                            text
                            @click="nextClick"
                            :disabled="disabledNext"
                          >
                            <v-icon dark>mdi-chevron-right</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-divider></v-divider>
        <v-data-table
          dense
          :headers="headers"
          :items="items"
          class="elevation-1"
          disable-pagination
          hide-default-footer
          :server-items-length="items.length"
        >
          <template v-if="items.length === 0" v-slot:no-data>
            <v-container>
              <v-row>
                <v-col md="6" offset-md="3">
                  <span class="title font-weight-bold">NO DATA AVAILABLE.</span>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-else v-slot:body="{ items }">
            <tbody v-for="(item, index) in items" :key="index">
              <tr v-for="(obj, i) in item.outlets" :key="i">
                <td
                  class="text-center"
                  :style="i === 0 ? '' : 'display: none;'"
                >
                  {{ index + queryParams.offset + 1 }}
                </td>
                <td
                  class="text-center text-no-wrap"
                  :style="i === 0 ? '' : 'display: none;'"
                >
                  {{ $dateFormat(item.appointment_date, "YYYY-MM-DD") }}
                </td>
                <td
                  class="text-no-wrap text-uppercase"
                  :style="i === 0 ? '' : 'display: none;'"
                >
                  {{ item.ticket_no }}
                </td>
                <td
                  class="text-no-wrap text-uppercase"
                  :style="i === 0 ? '' : 'display: none;'"
                >
                  {{ item.vehicle === null ? "" : item.vehicle.plate_no }}
                </td>
                <td
                  :style="
                    i === 1
                      ? 'border-bottom: none; vertical-align:top; padding-top: 25px;'
                      : 'display: none;'
                  "
                  colspan="4"
                  :rowspan="item.outlets.length"
                >
                  <b>Last Seen:</b>
                  &nbsp;
                  {{
                    item.current_status
                      ? item.current_status.datetimestamp
                      : "--"
                  }}
                  <br />
                  <b>Location:</b>&nbsp;
                  <span
                    class="viewCurrentLocation"
                    @click="viewCurrentLocation(item)"
                    >{{
                      item.current_status ? item.current_status.location : "--"
                    }}</span
                  >
                  <!-- <br />
                  <b>KM Run:</b>
                  {{ item.current_status ? 0 + "km" : "" }} -->
                </td>
                <td class="text-no-wrap text-uppercase">
                  {{ obj.outlet_name_1 }}
                </td>
                <td class="text-no-wrap text-uppercase">
                  {{ obj.outlet_address_1 ? obj.outlet_address_1 : "--" }}
                </td>
                <td class="text-center text-no-wrap">
                  {{ obj.eda ? obj.eda : "--" }}
                </td>
                <td class="text-center text-no-wrap">
                  {{ obj.eta ? obj.eta : "--" }}
                </td>
                <td class="text-no-wrap text-uppercase">
                  {{ obj.outlet_name_2 }}
                </td>
                <td class="text-no-wrap text-uppercase">
                  {{ obj.outlet_address_2 }}
                </td>
                <td class="text-center text-no-wrap">{{ obj.ada }}</td>
                <td class="text-center text-no-wrap">{{ obj.ata }}</td>
                <td class="text-center text-no-wrap">{{ obj.add }}</td>
                <td class="text-center text-no-wrap">{{ obj.atd }}</td>
                <!-- <td class="text-no-wrap text-uppercase">{{ obj.km_run }}</td> -->
                <td class="text-no-wrap text-center">
                  <excel-button
                    v-if="i === 0"
                    @click="downloadExcel(item)"
                  ></excel-button>
                  <cancel-dispatch-button
                    v-if="i === 0"
                    @click="openCancelDispatch(item)"
                  ></cancel-dispatch-button>
                  <close-dispatch-button
                    v-if="i === 0"
                    @click="openCloseDispatch(item)"
                  ></close-dispatch-button>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!---- Here is the Create Dispatch Dialog ----->

    <full-width-dialog
      :dialog="fullWidthDialog"
      :title="'Create Dispatch'"
      :color="'amber'"
      :dialogLoading="fullWidthDialogLoading"
      :dialogLoadingColor="'amber'"
      dark
      @closeDialog="closeCreateDispathcDialog()"
    >
      <v-container grid-list-xs fluid>
        <v-stepper class="elevation-0" v-model="e1" non-linear>
          <v-stepper-header class="grey lighten-4 pb-0">
            <v-stepper-step step="1" :complete="e1 > 1" color="amber"
              >Finalize Dispatch</v-stepper-step
            >
            <v-divider />
            <v-stepper-step step="2" :complete="e1 > 2" color="amber"
              >Upload Successful</v-stepper-step
            >
          </v-stepper-header>

          <v-stepper-items class="transparent">
            <v-stepper-content step="1" class="transparent pa-1">
              <v-container grid-list-xs fluid>
                <v-row
                  justify="center"
                  class="fill-height"
                  align-content="center"
                >
                  <v-col cols="12" sm="12" md="1" lg="1" xl="1"></v-col>
                  <v-col cols="12" sm="12" md="10" lg="10" xl="10">
                    <v-form ref="outletDispatchForm">
                      <v-row>
                        <v-col cols="12" sm="12" md="5" lg="5" xl="5">
                          <v-card>
                            <v-card-text>
                              <v-row>
                                <v-col cols="6">
                                  <v-select
                                    v-model="selectedVehicle"
                                    label="Choose Vehicle"
                                    :items="itemsSelectVehicle"
                                    required
                                    :rules="[rules.required]"
                                  ></v-select>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="4">
                                  <v-select
                                    v-model="selectedDriver"
                                    label="Choose Driver"
                                    :items="itemsSelectEmployee"
                                    required
                                    :rules="[rules.required]"
                                  ></v-select>
                                </v-col>
                                <v-col cols="4">
                                  <v-select
                                    v-model="selectedDispatcher"
                                    label="Choose Dispatcher"
                                    :items="itemsSelectEmployee"
                                    required
                                    :rules="[rules.required]"
                                  ></v-select>
                                </v-col>
                                <v-col cols="4">
                                  <v-select
                                    v-model="selectedHelper"
                                    label="Choose Helper"
                                    :items="itemsSelectEmployee"
                                    required
                                    :rules="[rules.required]"
                                  ></v-select>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="6">
                                  <v-dialog
                                    ref="appointmentDateDialog"
                                    v-model="appointmentDateModal"
                                    :return-value.sync="appointmentDate"
                                    persistent
                                    width="290px"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-model="appointmentDate"
                                        label="Appointment Date & Time"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        required
                                        :rules="[rules.required]"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="appointmentDate"
                                      scrollable
                                      :min="
                                        new Date().toISOString().substr(0, 10)
                                      "
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="appointmentDateModal = false"
                                        >Cancel</v-btn
                                      >
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="
                                          $refs.appointmentDateDialog.save(
                                            appointmentDate
                                          )
                                        "
                                        >OK</v-btn
                                      >
                                    </v-date-picker>
                                  </v-dialog>
                                </v-col>
                                <v-col cols="6">
                                  <input
                                    v-model="appointmentTime"
                                    type="time"
                                  />
                                </v-col>
                              </v-row>
                              <v-row no-gutters>
                                <v-col>
                                  <v-checkbox
                                    v-model="driverSMS"
                                    label="Send SMS to Driver"
                                  ></v-checkbox>
                                </v-col>
                              </v-row>
                              <!-- <v-row no-gutters>
                              <v-col>
                                <v-checkbox
                                  v-model="backToOriginStatus"
                                  label="Must return to Dispatch Outlet before closing"
                                ></v-checkbox>
                              </v-col>
                              </v-row>-->
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col cols="12" sm="12" md="7" lg="7" xl="7">
                          <v-card>
                            <v-card-text>
                              <v-data-table
                                dense
                                v-model="selectedOutlets"
                                :headers="headersSelectOutlets"
                                :items="itemsSelectOutlets"
                                hide-default-footer
                                :server-items-length="itemsSelectOutlets.length"
                              >
                                <template
                                  v-if="itemsSelectOutlets.length === 0"
                                  v-slot:no-data
                                >
                                  <v-container>
                                    <v-row>
                                      <v-col md="6" offset-md="3">
                                        <span class="title font-weight-bold"
                                          >NO DATA AVAILABLE.</span
                                        >
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </template>
                                <template v-else v-slot:body="{ items }">
                                  <tbody>
                                    <tr
                                      v-for="(item, index) in items"
                                      :key="index"
                                    >
                                      <td class="text-no-wrap text-center">
                                        <v-checkbox
                                          v-model="selectedOutlets"
                                          class="mt-0 pt-0"
                                          color="primary"
                                          hide-details
                                          :value="item"
                                        ></v-checkbox>
                                      </td>
                                      <td class="text-center text-no-wrap">
                                        {{ index + 1 }}
                                      </td>
                                      <td class="text-no-wrap text-uppercase">
                                        {{ item.name }}
                                      </td>
                                      <td
                                        @click="
                                          selectedOutlets.find((data) => {
                                            return data.id === item.id;
                                          })
                                            ? invalidEDADialog(index)
                                            : ''
                                        "
                                        :class="
                                          selectedOutlets.find((data) => {
                                            return data.id === item.id;
                                          }) && item.eda !== null
                                            ? 'dotted-underline black--text text-center text-no-wrap '
                                            : selectedOutlets.find((data) => {
                                                return data.id === item.id;
                                              }) && item.eda === null
                                            ? 'dotted-underline red--text text-center text-no-wrap '
                                            : item.eda === null
                                            ? 'red--text text-center text-no-wrap '
                                            : 'text-center text-no-wrap'
                                        "
                                      >
                                        {{
                                          item.eda === null
                                            ? "INVALID EDA"
                                            : `${$dateFormat(
                                                item.eda,
                                                "YYYY-MM-DD"
                                              )}`
                                        }}
                                      </td>
                                      <td
                                        @click="
                                          selectedOutlets.find((data) => {
                                            return data.id === item.id;
                                          })
                                            ? invalidETADialog(index, item.id)
                                            : ''
                                        "
                                        :class="
                                          selectedOutlets.find((data) => {
                                            return data.id === item.id;
                                          }) && item.eta !== null
                                            ? 'dotted-underline black--text text-center text-no-wrap '
                                            : selectedOutlets.find((data) => {
                                                return data.id === item.id;
                                              }) && item.eta === null
                                            ? 'dotted-underline red--text text-center text-no-wrap '
                                            : item.eta === null
                                            ? 'red--text text-center text-no-wrap '
                                            : 'text-center text-no-wrap'
                                        "
                                      >
                                        {{
                                          item.eta === null
                                            ? "INVALID ETA"
                                            : `${item.eta}`
                                        }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </template>
                              </v-data-table>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                  <v-col cols="12" sm="12" md="1" lg="1" xl="1">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          style="position: fixed; top: 350px; right: 30px;"
                          fab
                          color="amber white--text"
                          @click="openConfirmDispatchUploadDialog()"
                        >
                          <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                      </template>
                      <span>Next</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-content>
            <v-stepper-content step="2" class="transparent pa-1">
              <v-container grid-list-xs fluid>
                <v-row>
                  <v-col cols="1"></v-col>
                  <v-col cols="10">
                    <div class="scroll-wrapper">
                      <v-data-table
                        dense
                        :headers="saveUploadedItemsHeaders"
                        :items="saveUploadedItems"
                        class="elevation-1"
                        hide-default-footer
                        :server-items-length="saveUploadedItems.length"
                      >
                        <template
                          v-if="saveUploadedItems.length === 0"
                          v-slot:no-data
                        >
                          <v-container>
                            <v-row>
                              <v-col md="6" offset-md="3">
                                <span class="title font-weight-bold"
                                  >NO DATA AVAILABLE.</span
                                >
                              </v-col>
                            </v-row>
                          </v-container>
                        </template>
                        <template v-else v-slot:body="{ items }">
                          <tbody v-for="(item, index) in items" :key="index">
                            <tr v-for="(obj, i) in item.outlets" :key="i">
                              <td
                                class="text-center"
                                :style="i === 0 ? '' : 'visibility: hidden;'"
                              >
                                {{ index + 1 }}
                              </td>
                              <td
                                class="text-no-wrap text-uppercase"
                                :style="i === 0 ? '' : 'visibility: hidden;'"
                              >
                                {{ item.ticket_no }}
                              </td>
                              <td class="text-no-wrap text-uppercase">
                                {{
                                  item.vehicle === null
                                    ? ""
                                    : item.vehicle.plate_no
                                }}
                              </td>
                              <td class="text-no-wrap text-uppercase">
                                {{ obj.name }}
                              </td>
                              <td class="text-no-wrap text-uppercase">
                                {{ obj.address ? obj.address : "" }}
                              </td>
                              <td
                                class="text-center text-no-wrap text-uppercase"
                              >
                                {{ obj.eda ? obj.eda : "" }}
                              </td>
                              <td
                                class="text-center text-no-wrap text-uppercase"
                              >
                                {{ obj.eta ? obj.eta : "" }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-data-table>
                    </div>
                    <br />
                    <span class="title">
                      Note: Click to the
                      <u>
                        <b>Close (X)</b>
                      </u>
                      button to exit.
                    </span>
                  </v-col>
                  <v-col cols="1"></v-col>
                </v-row>
              </v-container>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-container>
    </full-width-dialog>

    <!------ Here is the Confirm Upload Dialog ------->

    <mini-dialog
      :dialog="confirmUploadDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      @closeDialog="confirmUploadDialog = false"
      @confirmDialog="saveDispatchUpload()"
    ></mini-dialog>

    <!----- End of the Confirm Upload Dialog -------->

    <!------ Here is the Invalid EDA Picker -------->

    <mini-dialog
      :dialog="openEDAPicker"
      :max-width="'350px'"
      :loading="dialogEDAETALoading"
      @closeDialog="openEDAPicker = false"
      @confirmDialog="selectedEDA()"
    >
      <v-card-text class="pa-0">
        <v-container fluid class="pa-0">
          <v-row no-gutters>
            <v-col class="pa-0">
              <v-date-picker
                v-model="eda"
                full-width
                class="elevation-0"
                style="height: 100%; border-radius: 0;"
                :min="new Date().toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </mini-dialog>

    <!------ Here is the Invalid ETA Picker -------->

    <mini-dialog
      :dialog="openETAPicker"
      :max-width="'350px'"
      :loading="dialogEDAETALoading"
      @closeDialog="openETAPicker = false"
      @confirmDialog="selectedETA()"
    >
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <input v-model="eta" type="time" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </mini-dialog>

    <!---- Here is the Confirmation of Cancel Dispatch ---->

    <mini-dialog
      :dialog="cancelDispatchDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      :cardTitle="'Cancel'"
      :cardText="'Are you sure you want to cancel this?'"
      @closeDialog="closeCancelDispatchDialog()"
      @confirmDialog="saveCancelDispatch()"
    ></mini-dialog>

    <!---- Here is the Confirmation of Close Dispatch ---->

    <mini-dialog
      :dialog="closeDispatchDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      :cardTitle="'Close'"
      :cardText="'Are you sure you want to close this?'"
      @closeDialog="closeCloseDispatchDialog()"
      @confirmDialog="saveCloseDispatch()"
    ></mini-dialog>

    <!---- Here is the View Current Location Dialog ------>

    <mini-dialog
      :dialog="viewCurrentLocationDialog"
      :max-width="'1000px'"
      :cardActions="false"
    >
      <v-card-title class="pl-3 gradientColor">
        <span class="headline font-weight-black white--text"
          >View Current Location</span
        >
        <v-spacer></v-spacer>
        <v-btn dark icon @click="closeViewCurrentLocationDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0" style="height: 500px;">
        <official-map ref="map"></official-map>
      </v-card-text>
    </mini-dialog>
  </v-container>
</template>

<script>
import Services from "@/services/SubCompanyServices";
import CompanyServices from "@/services/CompanyServices"
import moment from "moment";
import XLSX from "xlsx";

export default {
  data: () => {
    return {
      title: "Dispatch Monitoring",
      headers: [
        {
          text: "#",
          sortable: false,
          align: "center",
        },
        { text: "Appointment Date", sortable: false, align: "center" },
        {
          text: "Dispatch Ticket No",
          sortable: false,
        },
        { text: "Plate No", sortable: false },
        { text: "Outlet", sortable: false },
        { text: "Location", sortable: false },
        // { text: "EDD/EDA", sortable: false, align: "center" },
        // { text: "ETD/ETA", sortable: false, align: "center" },
        { text: "EDA", sortable: false, align: "center" },
        { text: "ETA", sortable: false, align: "center" },
        { text: "Outlet", sortable: false },
        { text: "Location", sortable: false },
        { text: "ADA", sortable: false, align: "center" },
        { text: "ATA", sortable: false, align: "center" },
        { text: "ADD", sortable: false, align: "center" },
        { text: "ATD", sortable: false, align: "center" },
        // { text: "KM Run", sortable: false },
        { text: "Actions", sortable: false, align: "center" },
      ],
      items: [],
      data: {},
      queryParams: {
        search: "",
        limit: 10,
        offset: 0,
      },
      rules: {
        required: (value) => !!value || "This field is required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      totalItems: 0,
      itemFrom: 0,
      itemTo: 0,
      disabledNext: false,
      disabledBack: true,
      disabledLimit: true,
      page: [
        { text: 10, value: 10 },
        { text: 20, value: 20 },
        { text: 30, value: 30 },
        { text: 40, value: 40 },
        { text: 50, value: 50 },
        { text: 60, value: 60 },
        { text: 70, value: 70 },
        { text: 80, value: 80 },
        { text: 90, value: 90 },
        { text: 100, value: 100 },
      ],
      confirmLoading: false,
      fullWidthDialog: false,
      fullWidthDialogLoading: false,
      e1: 1,
      itemsSelectVehicle: [],
      vehicleQueryParams: {
        search: "",
      },
      employeeQueryParams: {
        search: "",
      },
      outletQueryParams: {
        search: "",
      },
      itemsSelectEmployee: [],
      selectedDriver: null,
      selectedDispatcher: null,
      selectedHelper: null,
      selectedVehicle: null,
      eda: new Date().toISOString().substr(0, 10),
      eta: null,
      appointmentDate: new Date().toISOString().substr(0, 10),
      appointmentDateModal: false,
      appointmentTime: null,
      appointmentTimeModal: false,
      driverSMS: false,
      backToOriginStatus: false,
      selectedOutlets: [],
      headersSelectOutlets: [
        {
          text: "",
          sortable: false,
          align: "center",
        },
        {
          text: "#",
          sortable: false,
          align: "center",
        },
        {
          text: "Name",
          sortable: false,
        },
        {
          text: "EDA",
          sortable: false,
          align: "center",
        },
        {
          text: "ETA",
          sortable: false,
          align: "center",
        },
      ],
      itemsSelectOutlets: [],
      saveUploadedItems: [],
      saveUploadedItemsHeaders: [
        {
          text: "#",
          sortable: false,
          align: "center",
        },
        {
          text: "Dispatch Ticket No",
          sortable: false,
        },
        { text: "Plate No", sortable: false },
        { text: "Outlet", sortable: false },
        { text: "Location", sortable: false },
        // { text: "EDD/EDA", sortable: false, align: "center" },
        // { text: "ETD/ETA", sortable: false, align: "center" },
        { text: "EDA", sortable: false, align: "center" },
        { text: "ETA", sortable: false, align: "center" },
      ],
      confirmUploadDialog: false,
      index: 0,
      dialogEDAETALoading: false,
      openEDAPicker: false,
      openETAPicker: false,
      openMap: false,
      selectViewType: "table-view",
      selectViewTypeItems: [
        { text: "Table View", value: "table-view" },
        { text: "Map View", value: "map-view" },
      ],
      cancelDispatchDialog: false,
      closeDispatchDialog: false,
      viewCurrentLocationDialog: false,
    };
  },
  created: function() {
    if (this.$store.state.token !== null) {
      this.getData();
    } else {
      this.$router.push({
        path: "/",
      });

      this.$store.dispatch("clearStore");
    }
  },
  computed: {
    pageLimit: {
      get() {
        return 10;
      },
      set(val) {
        this.queryParams.limit = val;
        this.queryParams.offset = 0;
      },
    },
  },
  watch: {
    appointmentDate: function() {
      this.itemsSelectOutlets.forEach((item) => {
        item.eda = this.appointmentDate;
      });
    },
    appointmentTime: function() {
      this.itemsSelectOutlets.forEach((item) => {
        item.eta = this.appointmentTime;
      });
    },
  },
  methods: {
    search(value) {
      this.queryParams.search = value;
      this.setEmployees(this.queryParams);
    },
    async getData() {
      this.$store.dispatch("startLoading");

      // this.queryParams.sub_company_id = this.$store.state.subcompany.id;
      this.queryParams.company_id = this.$store.state.company.id;

      try {
        let response = (
          await CompanyServices.getTrackingDispatchMonitoringDispatches(
            this.queryParams
          )
        ).data;
        
        this.items = response.data;

        // Assign Total number of data
        this.totalItems = response.count;

        // Assign Number started the data
        this.itemFrom = this.totalItems > 0 ? this.queryParams.offset + 1 : 0;

        // Assign Number end the data
        this.itemTo = this.queryParams.offset + this.queryParams.limit;

        if (this.totalItems <= this.itemTo) {
          this.itemTo = this.totalItems;
        }

        // Conditions for NextPage Button
        if (this.itemTo === this.totalItems) {
          this.disabledNext = true;
        } else {
          this.disabledNext = false;
        }
        // Conditions for BackPage Button
        if (this.itemFrom > 1) {
          this.disabledBack = false;
        } else {
          this.disabledBack = true;
        }

        if (this.totalItems <= this.queryParams.limit) {
          this.disabledLimit = true;
        } else {
          this.disabledLimit = false;
        }

        this.$store.dispatch("dispatchHasItems", true);
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }

      this.$store.dispatch("endLoading");
    },
    nextClick() {
      this.queryParams.offset += this.queryParams.limit;
      this.getData();
    },
    backClick() {
      this.queryParams.offset -= this.queryParams.limit;
      this.getData();
    },
    limitClick() {
      this.queryParams.offset = 0;
      this.getData();
    },
    async initMap() {
      const boundsPolygon = [];
      const polygonsCoordinate = [];
      const plotRoute = [];

      await this.getData();

      await setTimeout(async () => {
        await this.$refs.map.initializeMap();

        if (this.items.length !== 0) {
          await Promise.all(
            this.items.map(async (item) => {
              const data = await this.setDispatch(item);

              const stopsArray = await Promise.all(
                data.stops_data.map(async (item) => {
                  return {
                    id: item.id,
                    coordinates: item.coordinates,
                    content: `Name: ${item.stop_name.toUpperCase()}`,
                  };
                })
              );

              stopsArray.forEach(async (item) => {
                item.coordinates.forEach((datum) => {
                  boundsPolygon.push({
                    lat: datum.lat,
                    lng: datum.lng,
                  });
                });

                polygonsCoordinate.push({
                  coordinates: item.coordinates,
                  content: item.content,
                  id: item.id,
                });

                plotRoute.push({
                  coordinates: item.coordinates,
                });

                await this.$refs.map.plotStops(item.coordinates);
              });

              const vehiclesArr = await Promise.all(
                data.vehicle_data.map((vehicle) => {
                  boundsPolygon.push({
                    lat: vehicle.lat,
                    lng: vehicle.lng,
                  });

                  return {
                    id: vehicle.id,
                    plate_no: vehicle.plate_no,
                    coordinates: {
                      lat: vehicle.lat,
                      lng: vehicle.lng,
                    },
                    content: `Plate No: ${vehicle.plate_no} <br> Status: ${vehicle.location}`,
                    image_url: require("../../assets/marker_icons/bus.png"),
                    in_dashboard:true,
                  };
                })
              );

              await this.$refs.map.insertMultipleMarker(vehiclesArr);
              await this.$refs.map.markerCluster();

              await this.$refs.map.boundsMarker(polygonsCoordinate);
              if (boundsPolygon.length !== 0) {
                await this.$refs.map.boundsOfAllMarkers(boundsPolygon);
              }

              // await this.$refs.map.makeRoute();
              await this.$refs.map.plottingRoute(plotRoute);
            })
          );
        }
      }, 1);
    },
    async openCreateDispatchDialog() {
      this.fullWidthDialog = true;
      this.fullWidthDialogLoading = true;

      this.selectedDriver = null;

      this.selectedDispatcher = null;

      this.selectedHelper = null;

      this.selectedVehicle = null;

      const driversItems = await this.setEmployees();

      const vehiclesItems = await this.setVehicles();

      const outletsItems = await this.setOutlets();

      this.itemsSelectEmployee = await Promise.all(
        driversItems.map((item) => {
          return {
            text: item.name.toUpperCase(),
            value: item.id,
          };
        })
      );

      this.itemsSelectVehicle = await Promise.all(
        vehiclesItems.map((item) => {
          return {
            text: item.plate_no.toUpperCase(),
            value: item.id,
          };
        })
      );

      this.itemsSelectOutlets = await Promise.all(
        outletsItems.map((item) => {
          return {
            address: item.address,
            company_id: item.companyId,
            coordinates: item.coordinates,
            id: item.id,
            name: item.name,
            eda: null,
            eta: null,
          };
        })
      );

      setTimeout(() => {
        this.fullWidthDialogLoading = false;
      }, 600);
    },
    async closeCreateDispathcDialog() {
      this.fullWidthDialog = false;
      this.itemsSelectVehicle.length = 0;
      this.itemsSelectEmployee.length = 0;
      this.itemsSelectOutlets.length = 0;
      this.selectedOutlets.length = 0;
      this.e1 = 1;
      await this.getData();
      this.uploadDialog = false;
    },
    confirmUpload() {
      this.confirmUploadDialog = true;
    },
    async invalidEDADialog(data) {
      this.index = data;
      this.eda = new Date().toISOString().substr(0, 10);
      this.dialogEDAETALoading = true;
      this.openEDAPicker = true;
      setTimeout(() => {
        this.dialogEDAETALoading = false;
      }, 600);
    },
    selectedEDA() {
      this.itemsSelectOutlets[this.index].eda = this.eda;

      const theIndex = this.selectedOutlets.findIndex((item) => {
        return item == this.itemsSelectOutlets[this.index];
      });

      this.selectedOutlets[theIndex].eda = this.eda;
      this.openEDAPicker = false;
    },
    async invalidETADialog(index, id) {
      const theIndex = this.selectedOutlets.findIndex((item) => {
        return item.id == id;
      });

      if (this.selectedOutlets[theIndex].eta) {
        this.eta = this.selectedOutlets[theIndex].eta;
      } else {
        this.eta = moment().format("HH:mm");
      }

      this.index = index;
      this.dialogEDAETALoading = true;
      this.openETAPicker = true;

      setTimeout(() => {
        this.dialogEDAETALoading = false;
      }, 600);
    },
    selectedETA() {
      this.itemsSelectOutlets[this.index].eta = this.eta;

      const theIndex = this.selectedOutlets.findIndex((item) => {
        return item == this.itemsSelectOutlets[this.index];
      });

      this.selectedOutlets[theIndex].eta = this.eta;
      this.openETAPicker = false;
    },
    openConfirmDispatchUploadDialog() {
      if (this.$refs.outletDispatchForm.validate()) {
        this.confirmUploadDialog = true;
      }
    },
    async saveDispatchUpload() {
      this.confirmLoading = true;

      const body = {
        driver_id: this.selectedDriver,
        dispatcher_id: this.selectedDispatcher,
        helper_id: this.selectedHelper,
        vehicle_id: this.selectedVehicle,
        company_id: this.$store.state.company.id,
        sub_company_id: this.$store.state.subcompany.id,
        appointment_datetime: this.$dateFormat(
          this.appointmentDate + " " + this.appointmentTime,
          "YYYY-MM-DD HH:mm:ss"
        ),
        driver_sms: this.driverSMS,
        outlets: this.selectedOutlets,
        // back_to_origin_status: this.backToOriginStatus,
        // is_all_outlet: this.isAllOutlet,
      };

      try {
        const response = await Services.saveDispatchMonitoringDispatch(body);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          const theResponse = response.data;

          this.saveUploadedItems = theResponse.data;

          this.e1 = 2;

          this.confirmUploadDialog = false;

          this.confirmLoading = await false;
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message),
          });
        }
      }
    },
    async viewCurrentLocation(data) {
      this.viewCurrentLocationDialog = true;

      const boundsVehicles = [];
      const vehiclesCoordinate = [];

      await setTimeout(async () => {
        this.$refs.map.initializeMap();

        let contentString = ``;

        contentString = `<b>Plate No.:</b> ${data.vehicle.plate_no.toUpperCase()}<br>
        <b>Location:</b> ${data.current_status.location.toUpperCase()}<br>`;

        boundsVehicles.push({
          lat: parseFloat(data.current_status.lat),
          lng: parseFloat(data.current_status.lng),
        });

        vehiclesCoordinate.push({
          coordinates: {
            lat: parseFloat(data.current_status.lat),
            lng: parseFloat(data.current_status.lng),
          },
          content: contentString,
          id: data.id,
          image_url: require("../../assets/marker_icons/bus.png"),
          in_dashboard:true,
        });

        await this.$refs.map.insertMultipleMarker(vehiclesCoordinate);
        if (boundsVehicles.length !== 0) {
          await this.$refs.map.boundsOfAllMarkers(boundsVehicles);
        }
      }, 1);
    },
    closeViewCurrentLocationDialog() {
      this.viewCurrentLocationDialog = false;
    },
    openCancelDispatch(item) {
      this.cancelDispatchDialog = true;
      this.data.id = item.id;
    },
    closeCancelDispatchDialog() {
      this.cancelDispatchDialog = false;
    },
    async saveCancelDispatch() {
      this.confirmLoading = true;

      try {
        const response = await Services.cancelDispatchMonitoringDispatch(
          this.data.id
        );

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeCancelDispatchDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text: "Dispatch has been cancelled!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }
      this.confirmLoading = await false;
    },
    openCloseDispatch(item) {
      this.closeDispatchDialog = true;
      this.data.id = item.id;
    },
    closeCloseDispatchDialog() {
      this.closeDispatchDialog = false;
    },
    async saveCloseDispatch() {
      this.confirmLoading = true;

      try {
        const response = await Services.closeDispatchMonitoringDispatch(
          this.data.id
        );

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeCloseDispatchDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text: "Dispatch has been closed!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }
      this.confirmLoading = await false;
    },
    async setVehicles() {
      let data;

      try {
        this.vehicleQueryParams.sub_company_id = this.$store.state.subcompany.id;

        const response = await Services.getVehicles(this.vehicleQueryParams);
        data = response.data.data;
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }
      return data;
    },
    async setEmployees() {
      let data = null;

      try {
        this.employeeQueryParams.sub_company_id = this.$store.state.subcompany.id;

        const response = await Services.getEmployees(this.employeeQueryParams);
        data = response.data.data;
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }

      return data;
    },
    async setOutlets() {
      let data = null;

      try {
        this.outletQueryParams.sub_company_id = this.$store.state.subcompany.id;

        const response = await Services.getOutletsMonitoring(
          this.outletQueryParams
        );
        data = response.data.data;
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }

      return data;
    },
    dispatchMonitoring() {
      this.$router.push({
        path: "/c/s/outlet-monitoring",
      });
    },
    activeMonitoring() {
      this.$router.push({
        path: "/c/s/active-monitoring",
      });
    },
    async downloadExcel(data) {
      let dataToDownload = [];
      let header = [];

      for (let i = 0; i < this.headers.length; i++) {
        header.push(this.headers[i].text);
      }

      header.splice(0, 1);

      header.pop();

      dataToDownload.push(header);

      for (let e = 0; e < data.outlets.length; e++) {
        let theItem = [];

        if (e === 0) {
          theItem.push(data.ticket_no);
          theItem.push(data.vehicle.plate_no.toUpperCase());

          if (
            "outlet_name_1" &&
            "outlet_address_1" &&
            "eda" &&
            "eta" in data.outlets[e]
          ) {
            theItem.push(
              data.outlets[e].outlet_name_1.toUpperCase()
                ? data.outlets[e].outlet_name_1.toUpperCase()
                : ""
            );
            theItem.push(
              data.outlets[e].outlet_address_1
                ? data.outlets[e].outlet_address_1.toUpperCase()
                : ""
            );
            theItem.push(data.outlets[e].eda ? data.outlets[e].eda : "");
            theItem.push(data.outlets[e].eta ? data.outlets[e].eta : "");
          } else {
            theItem.push("");
            theItem.push("");
            theItem.push("");
            theItem.push("");
          }

          theItem.push(
            data.outlets[e].outlet_name_2
              ? data.outlets[e].outlet_name_2.toUpperCase()
              : ""
          );
          theItem.push(
            data.outlets[e].outlet_address_2
              ? data.outlets[e].outlet_address_2.toUpperCase()
              : ""
          );
          theItem.push(data.outlets[e].ada ? data.outlets[e].ada : "");
          theItem.push(data.outlets[e].ata ? data.outlets[e].ata : "");
          theItem.push(data.outlets[e].add ? data.outlets[e].add : "");
          theItem.push(data.outlets[e].atd ? data.outlets[e].atd : "");
          theItem.push(data.outlets[e].km_run ? data.outlets[e].km_run : "");
        } else {
          theItem.push("");
          theItem.push("");

          if (
            "outlet_name_1" &&
            "outlet_address_1" &&
            "eda" &&
            "eta" in data.outlets[e]
          ) {
            theItem.push(
              data.outlets[e].outlet_name_1.toUpperCase()
                ? data.outlets[e].outlet_name_1.toUpperCase()
                : ""
            );
            theItem.push(
              data.outlets[e].outlet_address_1
                ? data.outlets[e].outlet_address_1.toUpperCase()
                : ""
            );
            theItem.push(data.outlets[e].eda ? data.outlets[e].eda : "");
            theItem.push(data.outlets[e].eta ? data.outlets[e].eta : "");
          } else {
            theItem.push("");
            theItem.push("");
            theItem.push("");
            theItem.push("");
          }

          theItem.push(
            data.outlets[e].outlet_name_2
              ? data.outlets[e].outlet_name_2.toUpperCase()
              : ""
          );
          theItem.push(
            data.outlets[e].outlet_address_2
              ? data.outlets[e].outlet_address_2.toUpperCase()
              : ""
          );
          theItem.push(data.outlets[e].ada ? data.outlets[e].ada : "");
          theItem.push(data.outlets[e].ata ? data.outlets[e].ata : "");
          theItem.push(data.outlets[e].add ? data.outlets[e].add : "");
          theItem.push(data.outlets[e].atd ? data.outlets[e].atd : "");
          theItem.push(data.outlets[e].km_run ? data.outlets[e].km_run : "");
        }

        dataToDownload.push(theItem);
      }

      // don't delete any codes after this comment got it?!

      let wscols = [
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
      ];

      let wsrows = [];

      let ws = XLSX.utils.aoa_to_sheet(dataToDownload, { cellDates: true });
      let wb = XLSX.utils.book_new();

      let fmt = "#,##0";

      let range = XLSX.utils.decode_range(ws["!ref"]);
      for (let e = range.s.r + 1; e <= range.e.r; ++e) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
          let ref = XLSX.utils.encode_cell({ r: e, c: C });
          if (!ws[ref]) continue;
          if (ws[ref].t !== "n") continue;
          ws[ref].z = fmt;
        }
      }

      XLSX.utils.book_append_sheet(wb, ws);

      ws["!cols"] = wscols;
      ws["!rows"] = wsrows;

      wb.Props = {
        Title: this.title,
        Subject: this.title,
        Author: "Developer of Journeytech",
        Company: "Journeytech",
        Category: "Reports",
      };

      // ws["!protect"] = {
      //   password: "Journeytech@1",
      //   formatRows: false,
      //   formatColumns: false,
      //   objects: true,
      //   scenarios: true
      // };

      if (!wb.Workbook) wb.Workbook = { Sheets: [], WBProps: {} };
      if (!wb.Workbook.WBProps) wb.Workbook.WBProps = {};
      wb.Workbook.WBProps.filterPrivacy = true;

      let filenames = [
        [this.title + ".xlsx"],
        [this.title + ".xlsm"],
        [this.title + ".xlsb"],
        [this.title + ".xlam"],
        [this.title + ".biff8.xls", { bookType: "xls" }],
        [this.title + ".biff5.xls", { bookType: "biff5" }],
        [this.title + ".biff2.xls", { bookType: "biff2" }],
        [this.title + ".xml.xls", { bookType: "xlml" }],
        [this.title + ".xla"],
        [this.title + ".ods"],
        [this.title + ".fods"],
        [this.title + ".csv"],
        [this.title + ".txt"],
        [this.title + ".slk"],
        [this.title + ".eth"],
        [this.title + ".htm"],
        [this.title + ".dif"],
        [this.title + ".dbf", { sheet: "Hidden" }],
        [this.title + ".rtf"],
        [this.title + ".prn"],
      ];
      let OUT = ["base64", "binary", "string", "array"];
      if (typeof Buffer !== "undefined") OUT.push("buffer");
      filenames.forEach(function(r) {
        XLSX.writeFile(wb, r[0], r[1]);
        if (typeof process !== "undefined") XLSX.readFile(r[0]);

        let ext = (r[1] && r[1].bookType) || r[0].split(".")[1];
        ext = { htm: "html" }[ext] || ext;
        OUT.forEach(function(type) {
          if (
            type === "string" &&
            [
              "xlsx",
              "xlsm",
              "xlsb",
              "xlam",
              "biff8",
              "biff5",
              "biff2",
              "xla",
              "ods",
              "dbf",
            ].indexOf(ext) > -1
          )
            return;
          if (
            type === "array" &&
            ["xlsx", "xlsm", "xlsb", "xlam", "ods"].indexOf(ext) > -1 &&
            typeof Uint8Array === "undefined"
          )
            return;
          let datout = XLSX.write(wb, {
            type: type,
            bookType: ext,
            sheet: (r[1] && r[1].sheet) || null,
          });
          XLSX.read(datout, { type: type });
          if (type === "array") console.log(ext, datout);
        });
      });
    },
    cutHeadings(data) {
      let text = null;
      text = data.length < 5 ? data : data.slice(0, 40) + "...";
      return text;
    },
    titleCase(str) {
      let splitStr = str.toLowerCase().split(" ");
      for (let i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },
  },
};
</script>

<style scoped>
input[type="time"] {
  /* border: 5px solid #b71c1c; */
  color: #000000;
  font-size: 14px;
  font-family: helvetica;
  width: 100%;
}

input[type="time"]:focus {
  outline: none;
}

/* Wrapper around the hour, minute, second, and am/pm fields as well as 
the up and down buttons and the 'X' button */
input[type="time"]::-webkit-datetime-edit-fields-wrapper {
  display: flex;
}

/* The space between the fields - between hour and minute, the minute and 
second, second and am/pm */
input[type="time"]::-webkit-datetime-edit-text {
  padding: 19px 4px;
}

/* The naming convention for the hour, minute, second, and am/pm field is
`-webkit-datetime-edit-{field}-field` */

/* Hour */
input[type="time"]::-webkit-datetime-edit-hour-field {
  background-color: #f2f4f5;
  border-radius: 15%;
  padding: 19px 25px;
}

/* Minute */
input[type="time"]::-webkit-datetime-edit-minute-field {
  background-color: #f2f4f5;
  border-radius: 15%;
  padding: 19px 25px;
}

/* AM/PM */
input[type="time"]::-webkit-datetime-edit-ampm-field {
  background-color: #b71c1c;
  border-radius: 15%;
  color: #fff;
  padding: 19px 25px;
}

/* 'X' button for resetting/clearing time */
input[type="time"]::-webkit-clear-button {
  display: none;
}

/* Up/Down arrows for incrementing/decrementing the value */
input[type="time"]::-webkit-inner-spin-button {
  display: none;
}

.viewCurrentLocation {
  cursor: pointer;
  color: red;
}

.viewCurrentLocation:hover {
  text-decoration: underline;
}

tbody tr:hover {
  background-color: transparent !important;
}

.my-border td:nth-child(1) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(2) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(3) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(4) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(5) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(6) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(7) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(8) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(9) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(10) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(11) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(12) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(13) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(14) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(15) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(16) {
  border-top: 1px solid #dddddd;
}

.anUnderline {
  text-decoration: underline;
  cursor: pointer;
}

.txt:hover {
  text-decoration: underline;
}

ol {
  list-style: none;
}

ol li:before {
  content: "\00BB \0020";
}

.gradientColor {
  background-color: #990000;
  background-image: linear-gradient(147deg, #990000 0%, #ff0000 74%);
}

.dotted-underline {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-style: dotted;
}

.scrolling-wrapper-dispatch {
  overflow-x: hidden;
  height: 300px;
  overflow-y: scroll;
  white-space: nowrap;
}

.scrolling-wrapper-finalize {
  overflow-x: hidden;
  height: 200px;
  overflow-y: scroll;
  white-space: nowrap;
}
</style>
